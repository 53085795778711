<template>
  <div class="flex-row" style="width: 100%">
    <el-date-picker v-model="timeval" type="daterange" class="timeclass" align="right" unlink-panels range-separator="至"
      start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="changetime">
    </el-date-picker>
  </div>
</template>
<script>
import {
  mapGetters
} from "vuex";

export default {
  name: "",
  components: {

  },
  props: {},
  data () {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一天',
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三天',
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近半年',
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一年',
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit('pick', [start, end]);
            }
          }]
      },
      timeval: [],
    }
  },
  created () {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    let endttime = this.getDate(end)
    let starttime = this.getDate(start)
    this.timeval = [starttime, endttime]
    this.changedata(starttime, endttime)
  },
  methods: {
    changetime (val) {
      let starttime = this.getDate(val[0])
      let endttime = this.getDate(val[1])
      this.changedata(starttime, endttime)
    },
    changedata (starttime, endttime) {
      this.$emit('change', [starttime, endttime])
    },
  }
}
</script>
<style scoped>
.timeclass {
  width: calc(100% - 10px);
}
</style>